import React from 'react'
import Navbar from '../Components/Navbar'
import Footer from '../Components/Footer'
import icon1 from '../Assets/modiji.webp'

function About() {
  return (
   <>
    <Navbar/>
 
 
    <div className='about-heading'>
    <br/>
    <br/>
    <br/>
    <h1>About Us</h1>

    </div>
    <div className='aboutus-section'>

<div className='aboutus-left'>
<img src={icon1}/>

</div>
<div className='aboutus-right'>
<p>TRAG is a multidisciplinary research group aiming to prevent losses of all kinds due to technology revolution. We work to make technology safer for society, infrastructure and environment and for continuous up gradation of know-hows.

We strive to use latest technology available to ensure that all possible risks of fire, explosion and dispersion are minimized and even if they occur effective mitigation strategies are adopted to control them. Continuous research efforts are being put to make use of the best available methods to reduce risks. Since experiments on all possible scenarios of fire, explosion and dispersion are not realizable state-of-art computational models can be the best alternative tools.

In our group all those expertise and resources are available which can answer many of your questions on risks with process, plant and infrastructure. We are dedicated set of individuals utilizing the tools which are well validated and widely accepted in the developed nations.</p>  
</div>
    </div>




    {/* <div className='founder'>
        <div className='founder-left'>
   
        <p>TRAG is a multidisciplinary research group aiming to prevent losses of all kinds due to technology revolution. We work to make technology safer for society, infrastructure and environment and for continuous up gradation of know-hows.

We strive to use latest technology available to ensure that all possible risks of fire, explosion and dispersion are minimized and even if they occur effective mitigation strategies are adopted to control them. Continuous research efforts are being put to make use of the best available methods to reduce risks. Since experiments on all possible scenarios of fire, explosion and dispersion are not realizable state-of-art computational models can be the best alternative tools.

In our group all those expertise and resources are available which can answer many of your questions on risks with process, plant and infrastructure. We are dedicated set of individuals utilizing the tools which are well validated and widely accepted in the developed nations.</p>
        <br/>
 

        <p>These industries include Additive Manufacturing, Electric Vehicle, Data Center, Chemical Engineering, Water and Environmental, Aerospace and Defense, Energy, Turbomachinery, Automotive, Mining, Microfluidics, Electronics, Food, and Consumer Products. </p>

</div>
 <div className='founder-right'>
<img src={icon1}/>
</div>

        </div> */}
        {/* <div className='text'>
        <div className='text-bar'>
        <h2>CFD consulting</h2>
        <br/>
        <p>TRAG provides low-cost and accurate CFD consulting services to design and improve existing processes in industries. We welcome challenging problems with open heart and promise solutions. We encourage the implementation of sustainable CFD practices in executing all our CFD projects. Be it from any industry, we have computational expertise for all industries. In all the scenarios, we work closely with clients to understand their unique needs and develop customized CFD solutions that bring desired results.</p>

</div>
        <div className='text-bar'>
        <h2>CFD consulting</h2>
        <br/>
        <p>TRAG provides low-cost and accurate CFD consulting services to design and improve existing processes in industries. We welcome challenging problems with open heart and promise solutions. We encourage the implementation of sustainable CFD practices in executing all our CFD projects. Be it from any industry, we have computational expertise for all industries. In all the scenarios, we work closely with clients to understand their unique needs and develop customized CFD solutions that bring desired results.</p>

</div>
        <div className='text-bar'>
        <h2>CFD consulting</h2>
        <br/>
        <p>TRAG provides low-cost and accurate CFD consulting services to design and improve existing processes in industries. We welcome challenging problems with open heart and promise solutions. We encourage the implementation of sustainable CFD practices in executing all our CFD projects. Be it from any industry, we have computational expertise for all industries. In all the scenarios, we work closely with clients to understand their unique needs and develop customized CFD solutions that bring desired results.</p>

</div>

        </div>
        <div className='text' style={{backgroundColor:'black', color:'white'}}>
        <div className='text-bar'>
        <h2>CFD consulting</h2>
        <br/>
        <p>TRAG provides low-cost and accurate CFD consulting services to design and improve existing processes in industries. We welcome challenging problems with open heart and promise solutions. We encourage the implementation of sustainable CFD practices in executing all our CFD projects. Be it from any industry, we have computational expertise for all industries. In all the scenarios, we work closely with clients to understand their unique needs and develop customized CFD solutions that bring desired results.</p>

</div>
        <div className='text-bar'>
        <h2>CFD consulting</h2>
        <br/>
        <p>TRAG provides low-cost and accurate CFD consulting services to design and improve existing processes in industries. We welcome challenging problems with open heart and promise solutions. We encourage the implementation of sustainable CFD practices in executing all our CFD projects. Be it from any industry, we have computational expertise for all industries. In all the scenarios, we work closely with clients to understand their unique needs and develop customized CFD solutions that bring desired results.</p>

</div>
        <div className='text-bar'>
        <h2>CFD consulting</h2>
        <br/>
        <p>TRAG provides low-cost and accurate CFD consulting services to design and improve existing processes in industries. We welcome challenging problems with open heart and promise solutions. We encourage the implementation of sustainable CFD practices in executing all our CFD projects. Be it from any industry, we have computational expertise for all industries. In all the scenarios, we work closely with clients to understand their unique needs and develop customized CFD solutions that bring desired results.</p>

</div>

        </div>
        <div className='text'>
        <div className='text-bar'>
        <h2>CFD consulting</h2>
        <br/>
        <p>TRAG provides low-cost and accurate CFD consulting services to design and improve existing processes in industries. We welcome challenging problems with open heart and promise solutions. We encourage the implementation of sustainable CFD practices in executing all our CFD projects. Be it from any industry, we have computational expertise for all industries. In all the scenarios, we work closely with clients to understand their unique needs and develop customized CFD solutions that bring desired results.</p>

</div>
        <div className='text-bar'>
        <h2>CFD consulting</h2>
        <br/>
        <p>TRAG provides low-cost and accurate CFD consulting services to design and improve existing processes in industries. We welcome challenging problems with open heart and promise solutions. We encourage the implementation of sustainable CFD practices in executing all our CFD projects. Be it from any industry, we have computational expertise for all industries. In all the scenarios, we work closely with clients to understand their unique needs and develop customized CFD solutions that bring desired results.</p>

</div>
        <div className='text-bar'>
        <h2>CFD consulting</h2>
        <br/>
        <p>TRAG provides low-cost and accurate CFD consulting services to design and improve existing processes in industries. We welcome challenging problems with open heart and promise solutions. We encourage the implementation of sustainable CFD practices in executing all our CFD projects. Be it from any industry, we have computational expertise for all industries. In all the scenarios, we work closely with clients to understand their unique needs and develop customized CFD solutions that bring desired results.</p>

</div>

        </div> */}
        {/* <div className='mission'>
        <h2>Our Mission</h2>
        <br/>
<p>We harness the latest technological innovations to minimize risks related to fire, explosion, and hazardous dispersion. Our approach focuses on prevention and effective mitigation strategies to manage potential incidents, ensuring that they are controlled swiftly and efficiently if they occur.</p>
        </div> */}
        <div className='WhatWedo'>
        <br/>
        <br/>
        <h1>What We Do</h1>
        <br/>

        <br/>
        <div className='text' style={{backgroundColor:'black', color:'white'}}>
        <div className='text-bar'>
        <h2>Risk Reduction</h2>
        <br/>
        <p>We employ cutting-edge technologies and methodologies to assess and reduce risks associated with processes, plants, and infrastructure.</p>

</div>

        <div className='text-bar'>
        <h2>Advanced Modeling</h2>
        <br/>
        <p>Recognizing the limitations of experimental simulations for all possible fire, explosion, and dispersion scenarios, we leverage state-of-the-art computational models as robust alternative tools. These models are rigorously validated and widely accepted in leading nations, enabling us to predict and manage potential risks effectively.</p>

</div>

        <div className='text-bar'>
        <h2>Continuous Research</h2>
        <br/>
        <p>Our team is engaged in ongoing research to develop and apply the best available methods for risk reduction. We strive to stay at the forefront of technological advancements to provide innovative solutions to emerging challenges.</p>

</div>

        </div>
        </div>
     
        {/* <div className='mission'>
        <h2>Our Expertise</h2>
        <br/>
<p>TRAG comprises a dedicated group of professionals with a wide range of expertise and resources. We offer comprehensive answers to complex questions concerning risks in various domains, including:</p>
       <br/>
       <li>
       Process Safety: Analysis and mitigation strategies for chemical processes and industrial operations.
       </li>
       <li>
       Plant Safety: Risk assessment and management for manufacturing plants and industrial facilities.
       </li>
       <li>
       Infrastructure Safety: Evaluation and enhancement of safety measures for public and private infrastructure.
       </li>
        </div> */}
     
    <Footer/>
   </>
  )
}

export default About