import React from 'react'
import Navbar from '../Components/Navbar'
import Footer from '../Components/Footer'

function Servies() {
  return (
    <>
        <Navbar/>
        <br/>
        <br/>
  
        <div className='about-heading'>
    <h1>Our Services</h1>
    <p>~Where Expertise Meets Excellence~</p>

    </div>
    <div className='Services-points'>
    <h2>Our Expertise: Where Expertise Meets Excellence</h2>
    <br/>
    <p>

At TRAG, expertise is the cornerstone of our operations. We take immense pride in our commitment to excellence and our unwavering dedication to delivering high-quality solutions for practical challenges.

With years of experience and a deep-seated passion for innovation and research, we have refined our skills to offer top-tier solutions across various industries.</p>
    
    </div>

    <div className='Services-points'>
    <h2>Research-Based Services We Offer</h2>
    <br/>
    <p>1.Testing & Measurement</p>
    <p>2.Email Modelling & Simulation </p>
    <p>3.Third-Party Review
    </p>
    <br/>
    <h2>Areas of Specialization:</h2>
    <br/>
    <p>1.Explosion Safety
</p>
    <p>2.Alternative Fuels and Emissions Control
    </p>
    <p>3.Fire Safety

    </p>
    <p>4.CFD Modelling


</p>
 <p>5.Risk Assessment

</p>

    </div>
    <br/>
    {/* <div className='activity'>
        <ul>
            <li>Water and Environmental </li>
            <li>Water and Environmental </li>
            <li>Water and Environmental </li>
            <li>Water and Environmental </li>

        </ul>
        <ul>
            <li>Water and Environmental </li>
            <li>Water and Environmental </li>
            <li>Water and Environmental </li>
            <li>Water and Environmental </li>

        </ul>
        <ul>
            <li>Water and Environmental </li>
            <li>Water and Environmental </li>
            <li>Water and Environmental </li>
            <li>Water and Environmental </li>

        </ul>
        <ul>
            <li>Water and Environmental </li>
            <li>Water and Environmental </li>
            <li>Water and Environmental </li>
            <li>Water and Environmental </li>

        </ul>
        </div> */}
        <Footer/>

    </>
  )
}

export default Servies