import Navbar from '../Components/Navbar'
import Footer from '../Components/Footer'
import React, { useState } from 'react';



// Data Arrays
const presentationsData = [
        { id: 19, title: "Schälike, S., Mishra, K.B., Wehrstedt, K.D., Schönbucher, A., Experimentelle Untersuchung der Massenabbrandraten multipler n-Heptan und Di-tert-butylperoxid Poolfeuer, 3. Magdeburger Brand- und Explosionsschutztag / vfdb-Workshop, 2013, Magdeburg, Germany." },
        { id: 18, title: "Mishra, K.B., Wehrstedt, K.-D., 2012, CFD Prediction of spill characteristics of liquid organic peroxides, IGUS EOS Meeting, 7-8 May 2012, Berlin, Germany." },
        { id: 17, title: "Gabriel, S., Mishra, K.B., Weidner, S., Molmassenabhängige Verteilung einer Dried Droplet Probe in Abhängigkeit von Lösungsmitteln und Polymeren, 16. Kolloquium MALDI und Synthetische Polymere, 8 May 2012, Berlin, Germany." },
        { id: 16, title: "Mishra, K.B., Wehrstedt, K.-D., 2012, Dispersion characteristics of flammable liquids: A CFD study, Proceedings of 4th International Congress on Computational Mechanics and Simulation (ICCMS 2012), Paper No. 413, 8-12 December, 2012, IIT Hyderabad, India." },
        { id: 15, title: "Mishra, K.B., Wehrstedt, K.-D., 2012, CFD simulation of hybrid fuel combustion, Proceedings of Thirty Ninth National Conference on Fluid Mechanics and Fluid Power (NCFMFP-12) Paper No. 209 , 13-15 December, 2012, SVNIT Surat, India, ISBN: 978-81-925-494-0-8." },
        { id: 14, title: "Mishra, K.B., Wehrstedt, K.-D., 2012, Applications of peroxy-fuels in vehicle propulsion, Proceedings of Combustion research and application (COMBURA -2012), 3-4 October 2012, Maastricht, The Netherlands." },
        { id: 13, title: "Mishra, K.B., Wehrstedt, K.-D., Krebs, H., 2012, Lessons learned from recent fuel storage fires, Proceedings of 11th International conference on combustion and energy utilization (ICCEU-2012), 9-13 May 2012, Coimbra, Portugal." },
        { id: 12, title: "Mishra, K.B., Wehrstedt, K.-D., 2011, Safety considerations for organic peroxide fires, IGUS Energetic and Oxidizing Substances (EOS) Working Group Meeting, 27-29 April 2011, Washington D.C., USA." },
        { id: 11, title: "Mishra, K.B., Wehrstedt, K.-D., Schönbucher, A., 2010, Pool and tank fires - Are we really prepared? 8th Asia-Pacific conference on combustion, 10-13 December 2010, Hyderabad, India." },
        { id: 10, title: "Mishra, K.B., Wehrstedt, K.-D., Schönbucher, A., 2010, Safety aspects of organic peroxide pool fires, Combustion research and application (COMBURA -2010), 12-13 October, 2010, Maastricht, The Netherlands." },
        { id: 9, title: "Mishra, K.B., Wehrstedt, K.-D., Schönbucher, A., 2010 Characterization of large pool fires of organic compounds containing oxygen atoms, 13th International symposium on loss prevention and safety promotion in the process industries, 6-9 June 2010, 365-372, Brugge, Belgium." },
        { id: 8, title: "Mishra, K.B., Experimental and numerical investigations of pool flames of organic peroxides, 29 September 2009, Institute for Combustion and Gas dynamics, University of Duisburg-Essen, Duisburg, Germany." },
        { id: 7, title: "Mishra, K.B., Wehrstedt, K.-D., Schönbucher, A., Pool Fires of Organic Peroxides: Experimental and CFD Study, 2009, IGUS Energetic and Oxidising Substances (EOS) Working Group Meeting, 10-12 June 2009, St. Petersburg, Russia." },
        { id: 6, title: "Mishra, K.B., 2009, An introduction to pool fire characteristics of organic peroxides, 4th Joint Colloquium of the Department II Chemical Safety Engineering of BAM and the Institute of Technical Chemistry I of the University of Duisburg-Essen, Berlin, Germany." },
        { id: 5, title: "Mishra, K.B., Wehrstedt, K.-D., Schönbucher, A., 2009, Impact of fuel properties on the large pool fire (hydrocarbons and organic peroxides) characteristics, 6th U. S. National Combustion meeting, 17-20 May 2009, Ann Arbor, MI, USA." },
        { id: 4, title: "Mishra, K.B., Wehrstedt, K.-D., Schönbucher, A., 2009, Safety characteristics of large pool fires of organic peroxides, 31 March 2009, ProcessNet-Arbeitsausschuss 'Sicherheitstechnische Kenngrößen', Frankfurt am Main, Germany." },
        { id: 3, title: "Mishra, K.B., Wehrstedt, K.-D., Schönbucher, A., 2008, On the dynamics of organic peroxide pool flames, Proceedings of 35th National Conference on Fluid Mechanics and Fluid Power (NCFFMFP-08), Bangalore, India." },
        { id: 2, title: "Mishra, K.B., 2007, A numerical revisit of large organic peroxide fire test, Viertes gemeinsames wissenschaftliches Kolloquium der University Duisburg-Essen, Institut für technische Chemie I, und der BAM, Abteilung II 'Chemische Sicherheitstechnik', 29 November 2007, Essen, Germany." },
        { id: 1, title: "Mishra, K.B., 2007, What do we reliably know about pool fire- Zweites gemeinsames wissenschaftliches Kolloquium der Uni Duisburg-Essen, Institut für technische Chemie I, und der BAM, Abteilung II 'Chemische Sicherheitstechnik', 19 April 2007, Essen, Germany." }
      ];
           
      const postersData = [
        { id: 11, title: "Vishwakarama, P., Mishra, K.B., Prediction of Multiple Fireballs Characteristics using FDS, International Conference on Sustainable Energy and Environmental Challenges-18-21 December, 2018, IIT Roorkee." },
        { id: 10, title: "Mishra, K.B., Wehrstedt K.-D., 2012, CFD analysis of peroxy-fuel combustion, 34th International Symposium on Combustion, 29 July- 3 August, 2012, Warsaw, Poland." },
        { id: 9, title: "Schälike, S., Mishra, K.B., Wehrstedt, K.D., Chun, H., Vela, I., Schönbucher, A., 2011, 8th European Congress of Chemical Engineering, 25-29 September, 2011, Berlin, Germany." },
        { id: 8, title: "Mishra, K.B., Wehrstedt, K.-D., Schönbucher, A., 2011, Interpretations of temperature measurements in organic peroxide pool fires, 5th European Combustion Meeting (ECM -2011), 25-26 May 2011, Cardiff University, UK." },
        { id: 7, title: "Mishra, K.B., Wehrstedt, K.-D., 2011, Combustion of peroxy-fuels, ERCOFTAC Conference: Highly Resolved Experimental and Numerical Diagnostics for Turbulent Combustion (HRTC-1), 28 June-2 July 2011, Rouen, France." },
        { id: 6, title: "Mishra, K.B., Wehrstedt, K.-D., Schönbucher, A., 2009, Numerical prediction of safety distances from large pool fires of organic peroxides, 40th International annual conference of ICT, 88, 1-14, Fraunhofer Institute of Chemical Technology, Pfinztal (Berghausen), Germany." },
        { id: 5, title: "Mishra, K.B., Wehrstedt, K.-D., Schönbucher, A., 2009, Turbulent Combustion in Pool Fires of Organic Peroxides, von Karman Institute for Fluid Dynamics, Lecture Series on 'Turbulent Combustion', 25-29 May 2009, VKI, Belgium." },
        { id: 4, title: "Mishra, K.B., Wehrstedt, K.-D., Schönbucher, A., 2009, Radiative characteristics of large pool fires of organic peroxides, 4th European Combustion Meeting, 14-17 April, 2009, Vienna, Austria." },
        { id: 3, title: "Mishra, K.B., Wehrstedt, K.-D., Schönbucher, A., Vela, I., 2008, Pool flames of organic peroxides: Experiments and CFD simulations, 9. Fachtagung 'Anlagen-, Arbeits- und Umweltsicherheit', 6-7 November 2009, Köthen, Germany." },
        { id: 2, title: "Mishra, K.B., Wehrstedt, K.-D., Schönbucher, A., 2008, Safety characterization of pool flames of organic peroxides, 9th International IAFSS (International Association for Fire Safety Science) Symposium, 21-26 September 2008, Karlsruhe, Germany." },
        { id: 1, title: "Mishra, K.B., Wehrstedt, K.-D., Vela, I., Schönbucher, A., 2008, Pool flames of organic peroxides - experimental and CFD study, 32nd International Symposium on Combustion, 3-8 August 2008, Montreal, Canada." }
      ];
      
  
function Presenrations() {
        const [openIndex, setOpenIndex] = useState(null);

        const toggleAccordion = (index) => {
          setOpenIndex(index === openIndex ? null : index);
        };
  return (
    <>
    <Navbar/>
    <div className='banner banner5'>
        <div className='overlay'>

<h1>
Poster & Presentations
</h1>
<p>This section provides a comprehensive list of Posters and Presentations delivered by us at various national and international platforms around the world. </p>
        </div>
        </div>
        <br/>
        <br/>

        <div className="container">
        {/* <h1>Presentations</h1> */}
        
        <section>
          <h2>Presentations</h2>
          <div className='list-patents'>
        {presentationsData.map((presentation, index) => (
          <div
            className='list-patent'
            key={presentation.id}
            onClick={() => toggleAccordion(index)}
          >
            <div className='acc-list'>
              <div>
                <div className='no'>
                  <h3 style={{ cursor: 'pointer' }}>{presentation.id}</h3>
                </div>
              </div>
              <h2>{presentation.title}</h2>
            </div>
            {openIndex === index && presentation.details && (
              <div className='accordion-body'>
                {presentation.details}
              </div>
            )}
          </div>
        ))}
      </div>
          {/* <ul>

            {presentationsData.map(presentation => (
              <li key={presentation.id}>{presentation.title}</li>
            ))}
          </ul> */}
        </section>


        <section>
          <h2>Posters</h2>
          <div className='list-patents'>
        {postersData.map((poster, index) => (
          <div
            className='list-patent'
            key={poster.id}
            onClick={() => toggleAccordion(index)}
          >
            <div className='acc-list'>
              <div>
                <div className='no'>
                  <h3 style={{ cursor: 'pointer' }}>{poster.id}</h3>
                </div>
              </div>
              <h2>{poster.title}</h2>
            </div>
            {openIndex === index && poster.details && (
              <div className='accordion-body'>
                {poster.details}
              </div>
            )}
          </div>
        ))}
      </div>
          {/* <ul>
            {postersData.map(poster => (
              <li key={poster.id}>{poster.title}</li>
            ))}
          </ul> */}
        </section>

      </div>



        {/* <div className='list-patents'>
        
       
        <h4>
        Presentations
        </h4>
        <div className='list-patent'>
   <div>
        <div className='no'>
<h3>1</h3>
        </div>
</div>

<h2>Schälike, S., Mishra, K.B., Wehrstedt, K.D., Schönbucher, A., Experimentelle Untersuchung der Massenabbrandraten multipler n-Heptan und Di-tert-butylperoxid Poolfeuer, 3. Magdeburger Brand- und Explosionsschutztag / vfdb-Workshop, 2013, Magdeburg, Germany. </h2>
</div>
   <div className='list-patent'>
       <div>
       <div className='no'>
<h3>2</h3>
        </div>
       </div>
<h2>Mishra, K.B., Wehrstedt, K.-D., 2012, CFD Prediction of spill characteristics of liquid organic peroxides, IGUS EOS Meeting, 7-8 May 2012, Berlin, Germany. 

</h2>
</div>
   <div className='list-patent'>
   <div>
        <div className='no'>
<h3>3</h3>
        </div>
</div>

<h2>Gabriel, S., Mishra, K.B., Weidner, S., Molmassenabhängige Verteilung einer Dried Droplet Probe in Abhängigkeit von Lösungsmitteln und Polymeren,  16. Kolloquium MALDI und Synthetische Polymere, 8 May 2012, Berlin, Germany.</h2>
</div>
   <div className='list-patent'>
   <div>

        <div className='no'>
<h3>4</h3>
        </div>
   </div>

<h2>Mishra, K.B., Wehrstedt, K.-D., 2012, Dispersion characteristics of flammable liquids: A CFD study, Proceedings of 4th International Congress on Computational Mechanics and Simulation (ICCMS 2012), Paper No. 413, 8-12 December, 2012, IIT Hyderabad, India.</h2>
</div>

        
        
        
       
     



<div className='list-patent'>
   <div>

        <div className='no'>
<h3>5</h3>
        </div>
   </div>

<h2>Mishra, K.B., Wehrstedt, K.-D., 2012, CFD simulation of hybrid fuel combustion, Proceedings of Thirty Ninth National Conference on Fluid Mechanics and Fluid Power (NCFMFP-12) Paper No. 209 , 13-15 December, 2012, SVNIT Surat, India, ISBN: 978-81-925-494-0-8.</h2>
</div>



<div className='list-patent'>
   <div>

        <div className='no'>
<h3>6</h3>
        </div>
   </div>

<h2>Mishra, K.B., Wehrstedt, K.-D., 2012, Applications of peroxy-fuels in vehicle propulsion, Proceedings of Combustion research and application (COMBURA -2012), 3-4 October 2012, Maastricht, The Netherlands. 

</h2>
</div>



<div className='list-patent'>
   <div>

        <div className='no'>
<h3>7</h3>
        </div>
   </div>

<h2>Mishra, K.B., Wehrstedt, K.-D., Krebs, H., 2012, Lessons learned from recent fuel storage fires, Proceedings  of 11th International conference on combustion and energy utilization (ICCEU-2012), 9-13 May 2012, Coimbra, Portugal.</h2>
</div>


<div className='list-patent'>
   <div>

        <div className='no'>
<h3>8</h3>
        </div>
   </div>

<h2>Mishra, K.B., Wehrstedt, K.-D., 2011, Safety considerations for organic peroxide fires, IGUS Energetic and Oxidizing Substances (EOS) Working Group Meeting, 27-29 April 2011, Washington D.C., USA.</h2>
</div>

<div className='list-patent'>
   <div>

        <div className='no'>
<h3>9</h3>
        </div>
   </div>

<h2>Mishra, K.B., Wehrstedt, K.-D., Schönbucher, A., 2010, Pool and tank fires - Are we really prepared?  8th Asia-Pacific conference on combustion, 10-13 December 2010, Hyderabad, India. </h2>
</div>


<div className='list-patent'>
   <div>

        <div className='no'>
<h3>10</h3>
        </div>
   </div>

<h2>Mishra, K.B., Wehrstedt, K.-D., Schönbucher, A., 2010, Safety aspects of organic peroxide pool fires, Combustion research and application (COMBURA -2010), 12-13 October, 2010, Maastricht, The Netherlands.</h2>
</div>


<div className='list-patent'>
   <div>

        <div className='no'>
<h3>11</h3>
        </div>
   </div>

<h2>Mishra, K.B., Wehrstedt, K.-D., Schönbucher, A., 2010 Characterization of large pool fires of organic compounds containing oxygen atoms, 13th International symposium on loss prevention and safety promotion in the process industries, 6-9 June 2010, 365-372,  Brugge, Belgium.</h2>
</div>

<div className='list-patent'>
   <div>

        <div className='no'>
<h3>12</h3>
        </div>
   </div>

<h2>Mishra, K.B., Experimental and numerical investigations of pool flames of organic peroxides, 29 September 2009, Institute for Combustion and Gas dynamics, University of Duisburg-Essen, Duisburg, Germany.</h2>
</div>


<div className='list-patent'>
   <div>

        <div className='no'>
<h3>13</h3>
        </div>
   </div>

<h2>Mishra, K.B., Wehrstedt, K.-D., Schönbucher, A., Pool Fires of Organic Peroxides: Experimental and CFD Study, 2009, IGUS Energetic and Oxidising Substances (EOS) Working Group Meeting, 10-12 June 2009, St. Petersburg, Russia.</h2>
</div>


<div className='list-patent'>
   <div>

        <div className='no'>
<h3>14</h3>
        </div>
   </div>

<h2>Mishra, K.B., 2009, An introduction to pool fire characteristics of organic peroxides, 4th Joint Colloquium of the Department II Chemical Safety Engineering of BAM and the Institute of Technical Chemistry I of the University of Duisburg-Essen, Berlin, Germany. </h2>
</div>



<div className='list-patent'>
   <div>

        <div className='no'>
<h3>15</h3>
        </div>
   </div>

<h2>Mishra, K.B., Wehrstedt, K.-D., Schönbucher, A., 2009, Impact of fuel properties on the large pool fire (hydrocarbons and organic peroxides) characteristics, 6th U. S. National Combustion meeting, 17-20 May 2009, Ann Arbor, MI, USA.</h2>
</div>


<div className='list-patent'>
   <div>

        <div className='no'>
<h3>16</h3>
        </div>
   </div>

<h2>Mishra, K.B., Wehrstedt, K.-D., Schönbucher, A., 2009, Safety characteristics of large pool fires of organic peroxides, 31 March 2009, ProcessNet-Arbeitsausschuss "Sicherheitstechnische Kenngrößen", Frankfurt am Main, Germany. </h2>
</div>



<div className='list-patent'>
   <div>

        <div className='no'>
<h3>16</h3>
        </div>
   </div>

<h2>Mishra, K.B., Wehrstedt, K.-D., Schönbucher, A., 2009, Safety characteristics of large pool fires of organic peroxides, 31 March 2009, ProcessNet-Arbeitsausschuss "Sicherheitstechnische Kenngrößen", Frankfurt am Main, Germany. </h2>
</div>




<div className='list-patent'>
   <div>

        <div className='no'>
<h3>17</h3>
        </div>
   </div>

<h2>Mishra, K.B., Wehrstedt, K.-D., Schönbucher, A., 2008, On the dynamics of organic peroxide pool flames, Proceedings of 35th National Conference on Fluid Mechanics and Fluid Power (NCFFMFP-08), Bangalore, India.</h2>
</div>




<div className='list-patent'>
   <div>

        <div className='no'>
<h3>18</h3>
        </div>
   </div>

<h2>Mishra, K.B., 2007, A numerical revisit of large organic peroxide fire test, Viertes gemeinsames wissenschaftliches Kolloquium der University Duisburg-Essen, Institut für technische Chemie I, und der BAM, Abteilung II "Chemische Sicherheitstechnik", 29 November 2007, Essen, Germany.</h2>
</div>


<div className='list-patent'>
   <div>

        <div className='no'>
<h3>19</h3>
        </div>
   </div>

<h2>Mishra, K.B., 2007, What do we reliably know about pool fire- Zweites gemeinsames wissenschaftliches Kolloquium der Uni Duisburg-Essen, Institut für technische Chemie I, und der BAM, Abteilung II "Chemische Sicherheitstechnik", 19 April 2007, Essen, Germany.</h2>
</div>

<h5>Invited Talks</h5>

<div className='list-patent'>
   <div>

        <div className='no'>
<h3>1</h3>
        </div>
   </div>

<h2>Mishra, K.B., Research Activities on Fire Safety at IIT-R, Workshop on Fire Research, 22 Jan. 2020, Aerospace engineering, IIT Madras.</h2>
</div>


<div className='list-patent'>
   <div>

        <div className='no'>
<h3>2</h3>
        </div>
   </div>

<h2>Sharma, A., Mishra, K.B., Performance-bsaed fire safety design of modern infrastructure, National Conference on Safety, Cochin University of Science and Technology, 12-15 December 2019, Cochin.</h2>
</div>



<div className='list-patent'>
   <div>

        <div className='no'>
<h3>3</h3>
        </div>
   </div>

<h2>Mishra, K.B., Modelling-based risk assessment of gas dispersion and explosion in real configuration, 2nd Oil and Gas HSE Conclave, 11-12 December 2019, New Delhi. </h2>
</div>


<div className='list-patent'>
   <div>

        <div className='no'>
<h3>4</h3>
        </div>
   </div>

<h2>Mishra, K.B., Computational modeling of industrial fire and explosion hazards, Recent Advances in Industrial Safety Engineering (RAISE-2019), TocH Institute of Science and Technology, Ernakulam, Kerala, India.</h2>
</div>



<div className='list-patent'>
   <div>

        <div className='no'>
<h3>5</h3>
        </div>
   </div>

<h2>Mishra, K.B., Modeling of fire and evacuation scenario in passenger aircraft, Proceedings of Aircraft Rescue and Firefighting conference, Hyderabad, 26-28 Feb. 2019. </h2>
</div>




<div className='list-patent'>
   <div>

        <div className='no'>
<h3>6</h3>
        </div>
   </div>

<h2>Mishra, K,B., Current research activities on fire and explosion safety at IIT-R, Center for Fire, Explosion and Environment Studies, Defense Research Development Organization, 4 May 2018, New Delhi.</h2>
</div>




<div className='list-patent'>
   <div>

        <div className='no'>
<h3>7</h3>
        </div>
   </div>

<h2>Mishra, K.B., Current research activities on fire safety at IIT-R, Loss Prevention Forum Meeting, 27-28 October 2017, FM Global Chennai. </h2>
</div>

<div className='list-patent'>
   <div>

        <div className='no'>
<h3>8</h3>
        </div>
   </div>

<h2>Mishra,K.B., Recent research activities on engine combustion and emission control at IIT-R, 12 October 2017, Workshop on Combustion at NCCRD,  IIT Madras, Chennai.</h2>
</div>


<div className='list-patent'>
   <div>

        <div className='no'>
<h3>9</h3>
        </div>
   </div>

<h2>Mishra, K.B., Recent research activities on safety ay IIT-R, 27 Jan. 2017, MITS Gwalior. </h2>
</div>



<div className='list-patent'>
   <div>

        <div className='no'>
<h3>10</h3>
        </div>
   </div>

<h2>Mishra, K.B., Wehrstedt, K.-D., 2012, Dispersion characteristics of flammable liquids: A CFD study, Proceedings of 4th International Congress on Computational Mechanics and Simulation (ICCMS 2012), 8-12 December, 2012, IIT Hyderabad, India.</h2>
</div>



<div className='list-patent'>
   <div>

        <div className='no'>
<h3>11</h3>
        </div>
   </div>

<h2>Mishra, K.B., Wehrstedt, K.-D., Schönbucher, A., Numerical studies of the pool fire characteristics of organic peroxides, 2009, MASCOT 2009, 9th Meeting on Applied Scientific Computing and Tools, IAC-CNR, 28-30 October 2009.</h2>
</div>
<div className='list-patent'>
   <div>

        <div className='no'>
<h3>12</h3>
        </div>
   </div>

<h2>Mishra, K.B., Wehrstedt, K.-D., Schönbucher, A., 2008, Experimental and numerical investigations of pool flames of organic peroxides, 1st Peer Training - Meeting on Applied Scientific Computing and Tools, IAC-CNR, 21-22 October 2008.</h2>
</div>



<h5>Posters</h5>

<div className='list-patent'>
   <div>

        <div className='no'>
<h3>1</h3>
        </div>
   </div>

<h2>Vishwakarama, P., Mishra, K.B., Prediction of Multiple Fireballs Characteristics using FDS, International Conference on Sustainable Energy and Environmental Challenges-18-21 December, 2018, IIT Roorkee. </h2>
</div>


<div className='list-patent'>
   <div>

        <div className='no'>
<h3>2</h3>
        </div>
   </div>

<h2>Mishra, K.B., Wehrstedt K.-D., 2012, CFD analysis of peroxy-fuel combustion, 34th International Symposium on Combustion, 29 July- 3 August, 2012, Warsaw, Poland. </h2>
</div>



<div className='list-patent'>
   <div>

        <div className='no'>
<h3>3</h3>
        </div>
   </div>

<h2>Schälike, S., Mishra, K.B., Wehrstedt, K.D., Chun, H., Vela, I., Schönbucher, A., 2011, 8th European Congress of Chemical Engineering, 25-29 September, 2011, Berlin, Germany. </h2>
</div>


<div className='list-patent'>
   <div>

        <div className='no'>
<h3>4</h3>
        </div>
   </div>

<h2>Mishra, K.B., Wehrstedt, K.-D., Schönbucher, A., 2011, Interpretations of temperature measurements in organic peroxide pool fires, 5th European Combustion Meeting (ECM -2011), 25-26 May 2011, Cardiff University, UK.</h2>
</div>



<div className='list-patent'>
   <div>

        <div className='no'>
<h3>5</h3>
        </div>
   </div>

<h2>Mishra, K.B., Wehrstedt, K.-D., 2011, Combustion of peroxy-fuels, ERCOFTAC Conference: Highly Resolved Experimental and Numerical Diagnostics for Turbulent Combustion (HRTC-1), 28 June-2 July 2011,Rouen, France.</h2>
</div>




<div className='list-patent'>
   <div>

        <div className='no'>
<h3>6</h3>
        </div>
   </div>

<h2>Mishra, K.B., Wehrstedt, K.-D., Schönbucher, A., 2009, Numerical prediction of safety distances from large pool fires of organic peroxides, 40th International annual conference of ICT, 88, 1-14, Fraunhofer Institute of Chemical Technology, Pfinztal (Berghausen), Germany.</h2>
</div>




<div className='list-patent'>
   <div>

        <div className='no'>
<h3>7</h3>
        </div>
   </div>

<h2>Mishra, K.B., Wehrstedt, K.-D., Schönbucher, A., 2009, Turbulent Combustion in Pool Fires of Organic Peroxides, von Karman Institute for Fluid Dynamics, Lecture Series on "Turbulent Combustion", 25-29 May 2009, VKI, Belgium.</h2>
</div>

<div className='list-patent'>
   <div>

        <div className='no'>
<h3>8</h3>
        </div>
   </div>

<h2>Mishra, K.B., Wehrstedt, K.-D., Schönbucher, A., 2009, Radiative characteristics of large pool fires of organic peroxides, 4th European Combustion Meeting, 14-17 April, 2009, Vienna, Austria.</h2>
</div>


<div className='list-patent'>
   <div>

        <div className='no'>
<h3>9</h3>
        </div>
   </div>

<h2>Mishra, K.B., Wehrstedt, K.-D., Schönbucher, A., Vela, I., 2008, Pool flames of organic peroxides: Experiments and CFD simulations, 9. Fachtagung "Anlagen-, Arbeits- und Umweltsicherheit", 6-7 November 2009, Köthen, Germany.</h2>
</div>



<div className='list-patent'>
   <div>

        <div className='no'>
<h3>10</h3>
        </div>
   </div>

<h2>Mishra, K.B., Wehrstedt, K.-D., Schönbucher, A., 2008, Safety characterization of pool flames of organic peroxides, 9th International IAFSS (International Association for Fire Safety Science) Symposium, 21-26 September 2008, Karlsruhe, Germany.</h2>
</div>



<div className='list-patent'>
   <div>

        <div className='no'>
<h3>11</h3>
        </div>
   </div>

<h2>Mishra, K.B., Wehrstedt, K.-D., Vela, I., Schönbucher, A., 2008, Pool flames of organic peroxides - experimental and CFD study, 32nd International Symposium on Combustion, 3-8 August 2008, Montreal, Canada.</h2>
</div>

<h5>
    Books
</h5>

<div className='list-patent'>
   <div>

        <div className='no'>
<h3>1</h3>
        </div>
   </div>

<h2>Mishra, K.B., Experimental investigation and CFD simulation of organic peroxide pool fires (TBPB and TBPEH), BAM-Dissertation Series 63, 2010 ISBN-1613-4249, ISSN- 1613-4249.</h2>
</div>


        </div> */}
    <Footer/>
   </>
  )
}

export default Presenrations
