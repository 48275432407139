import React, { useState } from 'react';
import Navbar from '../Components/Navbar';
import Footer from '../Components/Footer';
import { Link } from 'react-router-dom'
const patentsData = [
  {
    type: 'Granted',
    patents: [
      { id: 6, title: 'Mishra, K.B., Wehrstedt, K.-D., Rocket propellants, DE 10 2010 000 261 B4', details: 'https://patents.google.com/patent/DE102010000261B4/en?oq=DE102010000261B4' },
      { id: 5, title: 'Mishra, K.B., Wehrstedt, K.-D., Use of a fuel in a self-sustaining pulsating oxygen-fuel combustion process, DE 10 2009 039 893 B4, WO/2011/026741', details: 'https://patents.google.com/patent/WO2011026741A1/en?oq=Mishra%2c+K.B.%2c+Wehrstedt%2c+K.-D.%2c+Use+of+a+fuel+in+a+self-sustaining+pulsating+oxygen-fuel+combustion+process%2c+DE+10+2009+039+893+B4%2c+WO%2f2011%2f026741' },
      { id: 4, title: 'Mishra, K.B., Wehrstedt, K.-D., A more efficient and cleaner fuel for the processing industry, DE 10 2009 039 894 B4, WO/2011/11026742', details: 'https://patents.google.com/patent/DE102009039894B4/en?oq=DE102009039894+B4' },
      { id: 3, title: 'Trade mark: PEROXY-BAM® Burner, DE 30 2009 0699 18', details: 'https://register.dpma.de/DPMAregister/marke/register/3020090699186/DE' },
      { id: 2, title: 'Sharma, A., Mishra, K.B., 2017, A novel test stand and method to measure the burning rate of flammable liquids, Indian patent no. 531620.', details: 'https://iprsearch.ipindia.gov.in/PatentSearch/PatentSearch/eRegistrationReport' },
      { id: 1, title: 'Sharma, A., Mishra, K.B., Method and apparatus for testing fire behaviour of façade materials, Indian patent no 469636', details: 'https://iprsearch.ipindia.gov.in/PatentSearch/PatentSearch/eRegistrationReport' }
    ]
  },
  {
    type: 'Filed',
    patents: [
      { id: 15, title: 'Upadhyay, R.K., Mishra K.B, Ternary blends for improved performance and emission reduction in SI engines, Indian Patent Application No. 202411057302.', details: 'https://iprsearch.ipindia.gov.in/PatentSearch/PatentSearch/ViewApplicationStatus' },
      { id: 14, title: 'Mishra, S., Mishra, K.B., 2023, Apparatus for Testing Fuel Blends in Industrial Burners, Indian Patent Application No IN 202311019464', details: 'https://iprsearch.ipindia.gov.in/PatentSearch/PatentSearch/ViewApplicationStatus' },
      { id: 13, title: 'Mishra, S., Mishra, K.B., 2023, Test Method and Apparatus for Classification of Alcohol-Based Formulations, Indian Patent Application No. IN 202311007094.', details: 'https://iprsearch.ipindia.gov.in/PatentSearch/PatentSearch/ViewApplicationStatus' },
      { id: 12, title: 'Mishra, K.B., Wehrstedt, K.-D., CFD-EHD based source models for risk analysis, DE1020161183741A1', details: 'https://patents.google.com/patent/DE102016118374A1/en?oq=DE102016118374' },
      { id: 11, title: 'Mishra, K.B., Wehrstedt, K.-D., Process for the synthesis of nanomaterials using peroxy-fuels/blends flames, DE 10 2015 104 653.9', details: 'https://patents.google.com/patent/DE102015104653A1/en?oq=DE102015104653' },
      { id: 10, title: 'Mishra, K.B., Wehrstedt, K.-D., Process for reforming peroxy-fuels for hydrogen production, DE 10 2013 112927A1', details: 'https://patents.google.com/patent/DE102013112927A1/en?oq=DE102013112927A1' },
      { id: 9, title: 'Mishra, K.B., Wehrstedt, K.-D., Process for peroxy-coal combustion, DE 10 2013 104 356 A1', details: 'https://patents.google.com/patent/DE102013104356A1/en?oq=DE102013104356+A1' },
      { id: 8,title: 'Mishra, K.B., Wehrstedt, K.-D., Multi-burner heater, DE 20 2012 102 611 U1', details: 'https://patents.google.com/patent/DE202012102611U1/en?oq=DE202012102611U1' },
      { id: 7, title: 'Mishra, K.B., Wehrstedt, K.-D., Components for combustion engines operating on peroxy-fuels, DE 10 2011 051 228 A1', details: 'https://patents.google.com/patent/DE102011051228A1/en?oq=DE102011051228+A1' },
      { id: 6, title: 'Mishra, K.B., Wehrstedt, K.-D., Fuel mixtures and uses thereof in noise reduction in jet engines, DE10 2010 016 832 A1', details: 'https://patents.google.com/patent/DE102010016832A1/en?oq=DE102010016832A1' },
      { id: 5, title: 'Mishra, K.B., Wehrstedt, K.-D., Fuel mixtures and uses thereof in drying industries, DE10 2010 016 831 A1', details: 'https://patents.google.com/patent/DE102010016831A1/en?oq=DE102010016831A1' },
      { id: 4, title: 'Mishra, K.B., Wehrstedt, K.-D., Fuel mixtures and uses thereof in noise reduction in jet engines, DE10 2010 016 832 A1', details: 'https://patents.google.com/patent/DE102010016832A1/en?oq=DE102010016832A1' },
      { id: 3, title: 'Mishra, K.B., Wehrstedt, K.-D., Fuel mixture comprising tert – butyl peroxybenzoate and kerosene, and uses thereof, WO2011138395', details: 'https://patentimages.storage.googleapis.com/81/b3/93/d8953df3086bb0/WO2011138395A2.pdf' },
      { id: 2, title: 'Mishra, K.B., Wehrstedt, K.-D., WO/2011/091872, Burner for peroxy-fuels and furnace comprising such a burner, DE10 2010 000 248 A1', details: 'https://patents.google.com/patent/WO2011091872A2/en?oq=Mishra%2c+K.B.%2c+Wehrstedt%2c+K.-D.%2c+WO%2f2011%2f091872%2c+Burner+for+peroxy-fuels+and+furnace+comprising+such+a+burner%2c+DE10+2010+000+248+A1' },
      { id: 1, title: 'Mishra, K.B., Wehrstedt, K.-D., Fuel and use thereof, DE 10 2009 016 492A1, WO2010/115900 A1', details: 'https://patents.google.com/patent/WO2010115900A1/en?oq=%27Mishra%2c+K.B.%2c+Wehrstedt%2c+K.-D.%2c+Fuel+and+use+thereof%2c+DE+10+2009+016+492A1%2c+WO2010%2f115900+A1' }
    ]
  },
];

function PatentItem({ id, title, details, toggleAccordion, isOpen }) {
  // Function to convert details string into HTML
  const createMarkup = () => {
    return { __html: details };
  };

  return (
    <div className='list-patent' onClick={toggleAccordion}>
      <div className='acc-list'>
        <div>
          <div className='no'>
            <h3 style={{ cursor: 'pointer' }}>{id}</h3>
          </div>
        </div>
        <h2>{title}</h2>
      </div>
      <Link to= {details}>
      {isOpen && details && (
        <div dangerouslySetInnerHTML={createMarkup()} />
      )}
      </Link>
    </div>
  );
}


function Patents() {
  const [openPatent, setOpenPatent] = useState(null);

  const toggleAccordion = (id) => {
    setOpenPatent(openPatent === id ? null : id);
  };

  return (
    <>
      <Navbar />
      <div className='banner banner3'>
        <div className='overlay'>
          <h1>List of Patents</h1>
          <p>This page provides an extensive catalog of national and international patents granted to us. Click on the numbered circles to know more.</p>
        </div>
      </div>

      {patentsData.map((category) => (
        <div key={category.type} className='list-patents'>
          <h4>{category.type} Patents</h4>
          {category.patents.map((patent) => (
            <PatentItem
              key={patent.id}
              id={patent.id}
              title={patent.title}
              details={patent.details}
              toggleAccordion={() => toggleAccordion(patent.id)}
              isOpen={openPatent === patent.id}
            />
          ))}
        </div>
      ))}

      <Footer />
    </>
  );
}

export default Patents;
