import React, { useState } from 'react';

import logo from '../Assets/TRAG_bg-preview M.png'
import { Link } from 'react-router-dom';
function Navbar() {
  const [isDivVisible, setIsDivVisible] = useState(false);

  const toggleDivVisibility = () => {
    setIsDivVisible(!isDivVisible);
  };
  return (
    <div className='Navbar'>
    <div className='topnav'>
<p>World's first all-inclusive 3D Computational Software for Additive Manufacturing</p>
<button>Introduction and Demo</button>
    </div>
        <nav>
            <img src={logo}/>
        
            <ul>
            <Link className='none' to='/'>

                <li>Home</li>
            </Link>
            <Link className='none'  to='/about'>

                <li>About Us</li>
            </Link>
            <Link className='none' to='/motivation'>

<li>Motivation</li>
</Link>
     <Link className='none' to='/expertise'>

<li>Expertise</li>
</Link>

            <Link className='none' to='/Servies'>

<li>Services</li>
</Link>

       <Link className='none' to='/teams'>

<li>Team</li>
</Link> 
<li className="dropdown">
      <Link  className="none">
      Projects
      </Link>
      <div className="dropdown-content">
        <Link className="none" to="/consultancy">
        Consultancy Project
        </Link>
        <Link className="none" to="/sponsored">
        Sponsered Project
        </Link>
       
      </div>
    </li>
            
            <li className="dropdown">
      <Link className="none" >
        Publication
      </Link>
      <div className="dropdown-content">
        <Link  className="none" to="/patents">
        Patents
        </Link>
        <Link className="none" to="/journals">
        Reviewed Articles
        </Link>
        <Link className="none" to="/conferences">
        Conference Proceedings
        </Link>
        <Link className="none" to="/talks">
        Invited Talks
        </Link>
        <Link className="none" to="/books">
        Dissertations, Book chapters and Books
        </Link>
        <Link className="none" to="/presenrations">
        Posters & Presentations
        </Link>
        
      </div>
    </li>



           
           <Link className='none' to='/contact'>

<li>Contact Us</li>
</Link> 
               

            </ul>
            <i class="bi bi-list" onClick={toggleDivVisibility}></i>
        </nav>
        {isDivVisible && (
        <div className="toggleDiv-ops">
        <div className="toggleDiv">
          {/* Content of the div goes here */}
          <ul>
            <Link className='none' to='/'>

                <li>Home</li>
            </Link>
            <Link className='none'  to='/about'>

                <li>About Us</li>
            </Link>
            <Link className='none' to='/motivation'>

<li>Motivation</li>
</Link>
     <Link className='none' to='/expertise'>

<li>Our Expertise</li>
</Link>

            <Link className='none' to='/Servies'>

<li>Services</li>
</Link>

       <Link className='none' to='/teams'>

<li>Team</li>
</Link> 
 <Link className='none' to='/workwithus'>

<li>Work With Us</li>
</Link> 
<li className="dropdown">
      <Link style={{color:'white'}} className="none">
      Our Project
      </Link>
      <div className="dropdown-content">
        <Link className="none" to="/consultancy">
        Consultancy Project
        </Link>
        <Link className="none" to="/sponsored">
        Sponsered Project
        </Link>
       
      </div>
    </li>
            
            <li className="dropdown">
      <Link style={{color:'white'}} className="none" >
        Publication
      </Link>
      <div className="dropdown-content">
        <Link  className="none" to="/patents">
        Patents
        </Link>
        <Link className="none" to="/journals">
        Reviewed Articles
        </Link>
        <Link className="none" to="/conferences">
        Conference Proceedings
        </Link>  
        <Link className="none" to="/talks">
        Invited Talks
        </Link>
        <Link className="none" to="/books">
        Dissertations, Book chapters and Books
        </Link>
        <Link className="none" to="/presenrations">
        Posters and Presentations
        </Link>
        
      </div>
    </li>



           
           <Link className='none' to='/contact'>

<li>Contact Us</li>
</Link> 
               

            </ul>
        </div>
        </div>
      )}
    </div>
  )
}

export default Navbar
