import React from 'react'
import Navbar from '../Components/Navbar'
import Footer from '../Components/Footer'
import pm from '../Assets/fireman.jpg'
import e1 from '../Assets/e1.png'
import e2 from '../Assets/CFD Simulation of a Race Car using Ansys Fluent.jpeg'
import e3 from '../Assets/e3.jpg'
import e4 from '../Assets/e4.png'
import e5 from '../Assets/e5.jpg'
import e6 from '../Assets/e6.jpg'
import e7 from '../Assets/e8.jpeg'
import e8 from '../Assets/e7.jpeg'


function Expertise() {
  return (
    <>
        <Navbar/>
        
        <div className='banner banner22'>
        <div className='overlay'>

<h1>Our Expertise</h1>
<p>~ Where Expertise Meets Excellence ~</p>
        </div>
        </div>
        <div className='motivation'>
        <div className='motivation-left motivation-left2'>
       
       <br/>

        <h3>At TRAG, our expertise is at the heart of everything we do. We take pride in our commitment to excellence and our unwavering dedication to delivering high-quality solutions to practical problems.

With years of experience and a passion for innovation & research, we have honed our skills to offer you the best solutions in Industry.</h3>
        
</div>
  <div className='motivation-right'>
<img src={pm}/>
</div>
        </div>
        <div className='headingcenter'>
          <h2>We provide Research based services on</h2>
        </div>
        <br/>
        <br/>

        <br/>

        <div className='Expertise-cards'>
        <div className='Expertise-card'>
        <img src={e1}/>
<h3>Testing & Measurement</h3>
</div>
     <div className='Expertise-card'>
        <img src={e2}/>
<h3>Modelling & Simulation</h3>
</div>
     <div className='Expertise-card'>
        <img src={e3}/>
<h3>Third Party Review</h3>
</div>
        </div>
        <br/>
        <br/>
        <br/>
        <br/>

        <div className='headingcenter'>
          <h2>In the following areas</h2>
        </div>
        <br/>
        <br/>

        <br/>

        <div className='Expertise-cards'>
        <div className='Expertise-card'>
        <img src={e4}/>
<h3>Explosion Safety</h3>
</div>
     <div className='Expertise-card'>
        <img src={e5}/>
<h3>Alternative Fuels and <br/> Emissions Control</h3>
</div>
     <div className='Expertise-card'>
        <img src={e6}/>
<h3>Fire Safety</h3>
</div>
  <div className='Expertise-card'>
        <img src={e7}/>
<h3>CFD Modelling</h3>
</div>
  <div className='Expertise-card'>
        <img src={e8}/>
<h3>Risk Assessment</h3>
</div>
        </div>
        <br/>
        <br/>

        <Footer/>
    </>
  )
}

export default Expertise