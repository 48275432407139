import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./Pages/Home";
import About from "./Pages/About";
import OurProjects from "./Pages/OurProjects";
import Servies from "./Pages/Servies";
import Teams from "./Pages/Teams";
import Expertise from "./Pages/Expertise";
import Motivation from "./Pages/Motivation";
import Patents from "./Pages/Patents";
import Journals from "./Pages/Journals";
import Presenrations from "./Pages/Presenrations";
import Conferences from "./Pages/Conferences";
import Contact from "./Pages/Contact";
import Consultancy from "./Pages/Consultancy";
import 'bootstrap/dist/css/bootstrap.min.css';
import Sponsored from "./Pages/Sponsored";
import 'bootstrap/dist/css/bootstrap.css'
import ScrollToTop from "./Pages/ScrollToTop";
import Footer from "./Components/Footer";
import WorkWithUs from "./Pages/WorkWithUs";
import Books from "./Pages/Books";
import Talks from "./Pages/Talks";

function App() {
  return (
    <>

    <Router>

      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/project" element={<OurProjects />} />
        <Route path="/Servies" element={<Servies />} />
        <Route path="/teams" element={<Teams />} />
        <Route path="/expertise" element={<Expertise />} />
        <Route path="/motivation" element={<Motivation />} />
        <Route path="/patents" element={<Patents />} />
        <Route path="/journals" element={<Journals />} />
        <Route path="/conferences" element={<Conferences />} />
        <Route path="/presenrations" element={<Presenrations />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/consultancy" element={<Consultancy />} />
        <Route path="/sponsored" element={<Sponsored />} />
        <Route path="/workwithus" element={<WorkWithUs />} />
        <Route path="/books" element={<Books />} />
        <Route path="/talks" element={<Talks />} />
        

      </Routes>
 
    </Router>
  </>
  );
}

export default App;
