import React, { useState } from 'react'
import Navbar from '../Components/Navbar'
import Footer from '../Components/Footer'
import img from '../Assets/kb.jpg'
import t1 from '../Assets/Ankit_Sharma17.webp'
import t2 from '../Assets/samtrag1.webp'
import t3 from '../Assets/pV.webp'
import t4 from '../Assets/IMG_4901----8c.webp'
import t5 from '../Assets/Rajeeb Picture 001.webp'
import t7 from '../Assets/photo_page-0001_edited.webp'
import t6 from '../Assets/Gurumanoj1.webp'
import { Modal,ModalBody,ModalHeader } from 'reactstrap'
import arrow from '../Assets/1549612.png'
function Teams() {
  const [modal, setmodal] = useState(false);
  const [selectedMember, setSelectedMember] = useState(null);

  const teamMembers = [
    {
      id: 1,
      name: 'Ankit Sharma',
      img: t1,
      description: `Lorem Ipsum is simply dummy text of the printing and typesetting industry...`
    },
    {
      id: 2,
      name: 'Saumitra Mishra',
      img: t2,
      description: `Lorem Ipsum has been the industry's standard dummy text ever since...`
    },
    {
      id: 3,
      name: 'Pushpendra Vishwakarma',
      img: t3,
      description: `It has survived not only five centuries, but also the leap into...`
    },
    {
      id: 4,
      name: 'Boyeni Mahesh Yadav',
      img: t4,
      description: `It was popularised in the 1960s with the release of Letraset sheets...`
    },
    {
      id: 5,
      name: 'Shivam Gupta',
      img: t1,
      description: `More recently with desktop publishing software like Aldus PageMaker...`
    },
    {
      id: 6,
      name: 'Rajeeb Kumar Upadhyay',
      img: t5,
      description: `Lorem Ipsum passages, and more recently with desktop publishing software...`
    },
    {
      id: 7,
      name: 'Guru Manoj S',
      img: t6,
      description: `Lorem Ipsum is simply dummy text of the printing and typesetting industry...`
    },
    {
      id: 8,
      name: 'Ankit Patil',
      img: t7,
      description: `Lorem Ipsum has been the industry's standard dummy text...`
    }
  ];

  const togglemodal = (member) => {
    setSelectedMember(member);
    setmodal(!modal);
  };
  
  return (
    <>
        <Navbar/>
    <br/>
    <br/>
    <h2 style={{textAlign:'center'}}>Team Leader</h2>
<div className='team-main'>
<div className='team-main-left'>
<img src={img}/>
</div>
<div className='team-main-right'>
<h2>Dr. Kirti Bhushan Mishra</h2>
<br/>
<p>Dr. Kirti Bhushan Mishra is an Assistant Professor in Department of Mechanical and Industrial Engineering at Indian Institute of Technology (IIT) Roorkee. He is also the founder of Technological Risk Research and Analysis Group (TRAG) and in-charge of fuels, combustion and IC engines lab at IIT-R.  Dr. Mishra obtained his B.E from RGPV, Bhopal in 2001 and MTech from MANIT, Bhopal in 2003, both with distinctions. Dr. Mishra completed his PhD on fire safety and combustion from University of Duisburg-Essen (UDE), Germany in the year 2010. After his PhD he worked as Senior Scientific Officer at BAM Federal Institute for Materials Research and Testing in Berlin, Germany for more than five years. Since July 2015 he has been working as faculty in mechanical engineering department at IIT Roorkee.</p>
<p>He has 21 journal papers, 20 patents and 42 conference papers to his credit. He is also the recipient Early Career Research Award -2016 by Science and Engineering Research Board, Govt. of India. His areas of interests are combustion, fuels, fire, explosion, disaster prevention and CFD modelling. He is currently supervising 4 PhD students (one in Germany) working on different fields of combustion, fuels, fire, explosion safety and emission control.</p>
</div>
</div>
<br/>
<br/>
<h2 style={{textAlign:'center'}}>Our Current Working Team</h2>
<br/>

<div className='all-teams'>
      {teamMembers.map((member) => (
        <div className='team-card' key={member.id} onClick={() => togglemodal(member)}>
          <img src={member.img} alt={member.name} />
          <div className='team-name'>
            <p>{member.name}</p>
            <div>
            <img className='arrow' src={arrow}/>
            </div>
          </div>
        </div>
      ))}

      {selectedMember && (
        <Modal size='lg'
isOpen={modal}
toggle={() => togglemodal(null)}>
  <ModalHeader 
toggle={() => togglemodal(null)}>{selectedMember.name}</ModalHeader>
  <ModalBody>


<div className='modal-div'>
<div className='modal-left'>
<img src={selectedMember.img} alt={selectedMember.name} />

</div>  
  <div className='modal-right'>
  <p>{selectedMember.description}</p>
  </div>
</div>
</ModalBody>
</Modal>
       
      )}
    </div>


<br/>
<br/>

<h2 style={{textAlign:'center'}}>Our Past Working Team</h2>
<br/>

<div className='all-teams'>
      {teamMembers.map((member) => (
        <div className='team-card' key={member.id} onClick={() => togglemodal(member)}>
          <img src={member.img} alt={member.name} />
          <div className='team-name'>
            <p>{member.name}</p>
            <div>
           <div> <img className='arrow' src={arrow}/></div>
            </div>
          </div>
        </div>
      ))}

      {selectedMember && (
        <Modal size='lg'
isOpen={modal}
toggle={() => togglemodal(null)}>
  <ModalHeader 
toggle={() => togglemodal(null)}>{selectedMember.name}</ModalHeader>
  <ModalBody>


<div className='modal-div'>
<div className='modal-left'>
<img src={selectedMember.img} alt={selectedMember.name} />

</div>  
  <div className='modal-right'>
  <p>{selectedMember.description}</p>
  </div>
</div>
</ModalBody>
</Modal>
       
      )}
    </div>





        <Footer/>
    </>
  )
}

export default Teams