import React from 'react'
import Navbar from '../Components/Navbar'
import Footer from '../Components/Footer'
import img from '../Assets/Screenshot 2024-06-25 104820.png'
import img2 from '../Assets/project.png'
function OurProjects() {
  return (
    <>
        <Navbar/>
      
        <div className='about-heading'>
    <h1>Our Projects</h1>

    </div>
    <div className='project-div'>
    <h2>1. Improvement in Green anode quality using CFD-DEM coupling</h2>
    <br/>
    <p>A very complex physics is involved in the interaction of fluid (coal tar pitch) and particles (calcined coke particles) during the vibro-compaction process of the green anode. Tribology between fluid particles is also considered to achieve actual interactions taking place during vibro-compaction. Computational Fluid Dynamics (CFD) simulates coal-tar pitch while the Discrete Element Method (DEM) captures the interaction of coke particles. But to simulate the interaction of both pitch and particles in a single computational domain, a true CFD-DEM coupling is required. Here, we have done the CFD-DEM coupled simulation of coal-tar pitch with calcined coke particles for improved green anode quality.</p>
<br/>
<img src={img}/>
    </div>
    <div className='project-div'>
   <div className='project-div-con'>
   <div className='project-div-con-left'>
   <h2>2. HVAC modeling of electrical rooms </h2>
    <br/>
    <p>HVAC modeling is one of our key areas of expertise. Thanks to our recent projects !!!</p>

<br/>
<p>We performed HVAC modeling of electrical rooms of the World's largest social media company. We helped them understand </p>
<br/>
<p>1.Which cooling units should be used?<br/>

2.What should the cooling units be placed in the room?<br/>

3.Multiple scenarios of peak load operation and unit failures. </p>
<br/>
<p>
Our CFD solutions to this problem involved physics such as turbulence, buoyancy effect, and detailed conjugate heat transfer.</p>

</div>
 <div className='project-div-con-right'>

</div>
   </div>
    </div>
     <div className='project-div'>
   <div className='project-div-con'>
   <div className='project-div-con-left'>
   <h2>3. Large-scale environmental modeling</h2>
    <br/>
    <p>This project focuses on external HVAC and environmental modeling of large-scale data farms. These areas are about  0.5×0.33×02 Km3.</p>

<br/>
<p>Dry coolers are used to extract the heat from the hot fluids coming from the data centers. They exchange heat and make them reusable for data center cooling and recycle the liquid. This huge amount of extracted heat is disposed of in the environment. Thus minimizing the heat island effect on the surrounding areas is of utmost importance. The effect of wind speed and direction becomes rather important in carrying away this plume of heat. TRAG not only successfully delivered the project with complete sincerity but also helped them use this CFD data through an AI agent for better decision-making in the future, known as DC Pracar.</p>
<br/>

</div>
 <div className='project-div-con-right'>

</div>
   </div>
    </div>
    <div className='project-div'>
    <h2>4. Smart metering system for reduced water footprint</h2>
    <br/>
    <p>Water is a valuable asset, therefore, monitoring how much water is consumed has become the need of the hour. This computational modeling project is done for a real-time implementation of a flow meter in a water line to monitor the water footprint. The use of a flow conditioner in a water line is used to reduce the turbulence in the line thus achieving the fully developed flow sooner.</p>
<br/>
<p>A transient flow CFD analysis of water is done in a single-diameter pipe around the proposed flow meter for optimizing the design of flow conditioners and the length between a flow conditioner and the flow meter.</p>
<br/>
<p>The goal of this industrial project is to minimize the turbulence, minimizing the partial flow and minimizing the pressure drop.</p>

<img src={img2}/>
    </div>
    <div className='project-div project-div2'>
    <h2>5. AI for CFD-based air duct design optimization</h2>
    <br/>
    <br/>

    <p>The goal of this problem was to produce a simulation model which can be used to create a simulation dataset. We have the hardware/software infrastructure to produce the few hundred design points that we need, which is why we only need a simulation model and not the whole dataset. </p>
<br/>
<br/>

<p>Multivariable constrained optimization</p>
<br/>
<p>1.Duct size and shape optimization</p>
<p>2.Inlet parameters optimization</p>
<p>3.Heat exchanger optimization</p>
<p>4.Gate angle optimization</p>
<p>5.Evaporator optimization</p>


    </div>
        <Footer/>
    </>
  )
}

export default OurProjects