import React, { useEffect, useState } from "react";
import logo from '../Assets/TRAG-removebg-preview.png'
import { Link } from 'react-router-dom'

function Footer() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
       <div className='footer'>
       <ul className='footer-ul'>
            <li><img className='newlogo' src={logo}/></li>
            <li><button>TRAG</button></li>
           
        </ul>
        <ul>
        <Link className='none2' to='/'>

            <li>Home</li>
        </Link>
            <Link className='none2' to='/about'>
          
            <li>About Us</li>
          </Link>
            <Link className='none2' to='/contact'>
          
            <li>Contact Us</li>
          </Link>
            <Link className='none2' to='/teams'>
          
            <li>Teams</li>
          </Link>
          


        </ul>
        <ul>
        <Link className='none2' to='/Servies'>
          
            <li>Servies</li>
          </Link>
            <Link className='none2' to='/expertise'>
          
            <li>Our Expertise</li>
          </Link>
            <Link className='none2' to='/motivation'>
          
            <li>Motivation</li>
          </Link>
          <Link className='none2' to='/workwithus'>
          
          <li>Work With Us</li>
        </Link>


        </ul>
        <ul>
            <li>Follow Us :</li>
            <li>
           
            <Link className='none2' to='https://www.linkedin.com/school/indian-institute-of-technology-roorkee/?originalSubdomain=in'>

            <i class="bi bi-linkedin"></i>
            </Link>
            <Link className='none2' to='https://x.com/iitroorkee?ref_src=twsrc%5Egoogle%7Ctwcamp%5Eserp%7Ctwgr%5Eauthor'>

            <i class="bi bi-twitter"></i>
            </Link>
            <Link className='none2' to='https://www.facebook.com/IITRoorkee.ICC/'>

            <i class="bi bi-facebook"></i>
            </Link>
            <Link className='none2' to='https://www.instagram.com/iitroorkee/?hl=en'>

            <i class="bi bi-instagram"></i>
            </Link>
            <Link className='none2' to='https://www.youtube.com/channel/UCWzelmid9BHgokVfVGhWvLw'>

<i class="bi bi-youtube"></i>
</Link>
            </li>



        </ul>
    
       </div>
       <div className='bottom-foot'>
<p>Designed and Developed by <span><Link className='flink' to='https://www.strixdigital.in/'>Strix Digital</Link></span></p>
       </div>
    </>
  )
}

export default Footer