import React from 'react'
import Navbar from '../Components/Navbar'
import Footer from '../Components/Footer'
import pm from '../Assets/modiji.webp'
function Motivation() {
  return (
    <>
        <Navbar/>
        <div className='banner banner11'>
        <div className='overlay '>

<h1>Motivation</h1>
<p>~ Striving for a safer tomorrow ~</p>
        </div>
        </div>
        <div className='motivation'>
        <div className='motivation-left'>
        <h2>We, together, can make India a Fire Safe and Loss Free Nation</h2>
       <br/>
       <br/>

        <p>India has the second highest number of deaths and injuries in the world resulting from workplace related accidents and illnesses. The country suffers a loss of at least 4 percent of its annual GDP to workplace related accidents and injuries.​</p>
        <br/>

<p>Proactive research would help organizations make informed decisions to enhance safety in the workplace. Our aim is clear and simple that we want to make India a technologically safer nation  through research based innovation and state-of-art experimental and computational tools.</p>
<br/>

<p>We are fortunate to be blessed with our Honorable Prime Minister Mr. Narendra Modi.</p>
</div>
  <div className='motivation-right'>
<img src={pm}/>
</div>
        </div>

        <Footer/>
    </>
  )
}

export default Motivation