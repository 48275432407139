import React from 'react'
import Navbar from '../Components/Navbar'
import Footer from '../Components/Footer'
import { Link } from 'react-router-dom';

function Consultancy() {
    const projects = [
        {
          title: 'Development of multi-layered firefighter’s suit for protection against thermal hazards and pressurized steam',
          agency: 'Ministry of Textiles, Govt. of India',
          role: 'Co-PI Lab Partner',
          startDate: '2022',
          endDate: '2024',
          status: 'Ongoing',
          url: 'https://www.trag.org.in/'

        },
        {
          title: 'CFD modeling of dust dispersion and explosion in a pet coke dome',
          agency: 'Tata Projects Limited, Mumbai',
          role: 'PI',
          startDate: '2022',
          endDate: '',
          status: 'Completed',
          url: 'https://www.trag.org.in/'

        },
        {
          title: 'Modelling of tunnel fires',
          agency: 'Fox Engineering, Pune',
          role: 'PI',
          startDate: '01/03/2020',
          endDate: '01/07/2020',
          status: 'Completed',
          url: 'https://www.trag.org.in/'
        },
        {
          title: 'UL-94 Testing',
          agency: 'IOCL Panipat',
          role: 'PI',
          startDate: '01/01/2020',
          endDate: '01/04/2021',
          status: 'Completed',
          url: 'https://www.trag.org.in/'
        }
      ];
  return (
   <>
    <Navbar/>
    <div className='banner banner2'>
        <div className='overlay'>

<h1>Consultancy Projects</h1>

        </div>
        </div>


    <div className="container mt-5">
    <div className="table-responsive">
      <table className="table table-striped table-bordered">
        <thead className="thead-dark">
          <tr>
            <th scope="col">Title</th>
            <th scope="col">Sponsoring Agency</th>
            <th scope="col">PI or Co-PI</th>
            <th scope="col">Start date</th>
            <th scope="col">End date</th>
            <th scope="col">Status</th>
            <th scope="col">Action</th>

          </tr>
        </thead>
        <tbody>
          {projects.map((project, index) => (
            <tr key={index}>
              <td>{project.title}</td>
              <td>{project.agency}</td>
              <td>{project.role}</td>
              <td>{project.startDate}</td>
              <td>{project.endDate}</td>
              <td>{project.status}</td>
              <td>
                    {project.url ? (
                      <a href={project.url} className="btn btn-primary" target="_blank" rel="noopener noreferrer">View More</a>
                    ) : (
                      'N/A'
                    )}
                  </td>

            </tr>
          ))}
        </tbody>
      </table>
    </div>
    </div>



<br/>
<br/>
    <Footer/>

   </>
  )
}

export default Consultancy