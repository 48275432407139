import React, { useState } from 'react';
import Navbar from '../Components/Navbar';
import Footer from '../Components/Footer';


function TalkItem({ id, title, details, toggleAccordion, isOpen }) {
  // Function to convert details string into HTML
  const createMarkup = () => {
    return { __html: details };
  };

  return (
    <div className='list-talk' onClick={toggleAccordion}>
      <div className='acc-list'>
        <div>
          <div className='no'>
            <h3 style={{ cursor: 'pointer' }}>{id}</h3>
          </div>
        </div>
        <h2>{title}</h2>
      </div>
      {isOpen && details && (
        <div dangerouslySetInnerHTML={createMarkup()} />
      )}
    </div>
  );
}


function Talks() {
  const [openTalk, setOpenTalk] = useState(null);

  const toggleAccordion = (id) => {
    setOpenTalk(openTalk === id ? null : id);
  };

  const talksData = [
    {
      id: 12, 
      title: "12. Mishra, K.B., Research Activities on Fire Safety at IIT-R, Workshop on Fire Research, 22 Jan. 2020, Aerospace engineering, IIT Madras.",
    },
    {
      id: 11, 
      title: "11. Mishra, K.B., Research Activities on Fire Safety at IIT-R, Workshop on Fire Research, 22 Jan. 2020, Aerospace engineering, IIT Madras.",
    },
    {
      id: 10, 
      title: "10. Sharma, A., Mishra, K.B., Performance-based fire safety design of modern infrastructure, National Conference on Safety, Cochin University of Science and Technology, 12-15 December 2019, Cochin.",
    },
    {
      id: 9, 
      title: "9. Mishra, K.B., Modelling-based risk assessment of gas dispersion and explosion in real configuration, 2nd Oil and Gas HSE Conclave, 11-12 December 2019, New Delhi.",
    },
    {
      id: 8, 
      title: "8. Mishra, K.B., Modeling of fire and evacuation scenario in passenger aircraft, Proceedings of Aircraft Rescue and Firefighting conference, Hyderabad, 26-28 Feb. 2019.",
    },
    {
      id: 7, 
      title: "7. Mishra, K.B., Current research activities on fire and explosion safety at IIT-R, Center for Fire, Explosion and Environment Studies, Defense Research Development Organization, 4 May 2018, New Delhi.",
    },
    {
      id: 6, 
      title: "6. Mishra, K.B., Current research activities on fire safety at IIT-R, Loss Prevention Forum Meeting, 27-28 October 2017, FM Global Chennai.",
    },
    {
      id: 5, 
      title: "5. Mishra, K.B., Recent research activities on engine combustion and emission control at IIT-R, 12 October 2017, Workshop on Combustion at NCCRD, IIT Madras, Chennai.",
    },
    {
      id: 4, 
      title: "4. Mishra, K.B., Recent research activities on safety at IIT-R, 27 Jan. 2017, MITS Gwalior.",
    },
    {
      id: 3, 
      title: "3. Mishra, K.B., Wehrstedt, K.-D., 2012, Dispersion characteristics of flammable liquids: A CFD study, Proceedings of 4th International Congress on Computational Mechanics and Simulation (ICCMS 2012), 8-12 December, 2012, IIT Hyderabad, India.",
    },
    {
      id: 2, 
      title: "2. Mishra, K.B., Wehrstedt, K.-D., Schönbucher, A., Numerical studies of the pool fire characteristics of organic peroxides, 2009, MASCOT 2009, 9th Meeting on Applied Scientific Computing and Tools, IAC-CNR, 28-30 October 2009.",
    },
    {
      id: 1, 
      title: "1. Mishra, K.B., Wehrstedt, K.-D., Schönbucher, A., 2008, Experimental and numerical investigations of pool flames of organic peroxides, 1st Peer Training - Meeting on Applied Scientific Computing and Tools, IAC-CNR, 21-22 October 2008.",
    },
  ];

  return (
    <>
      <Navbar />
      <div className='banner banner-talks'>
        <div className='overlay'>
          <h1>Invited Talks</h1>
          <p>This page provides a list of technical talks delivered by experts at various national and international proceedings and workshops</p>
        </div>
      </div>

      <div className='list-talks'>
        {talksData.map((talk) => (
          <TalkItem
            key={talk.id}
            id={talk.id}
            title={talk.title}
            details={talk.details}
            toggleAccordion={() => toggleAccordion(talk.id)}
            isOpen={openTalk === talk.id}
            />
          ))}
        </div>
      ))}

         <Footer />
    </>
  );
}

export default Talks;


