import React, { useEffect, useState } from "react";
import Navbar from '../Components/Navbar'
import p1 from '../Assets/56d6a8_65c1045993404f11a6041e9ab4cd12a7~mv2.webp'
import p2 from '../Assets/56d6a8_be56f826bf6e4887a8f0feeab943bbbd~mv2.webp'
import p3 from '../Assets/partner3.png'
import map from '../Assets/image (15).png'
import Footer from '../Components/Footer'
import dir from '../Assets/kb.jpg'
import e1 from '../Assets/event12.webp'
import e2 from '../Assets/event1.webp'
import e3 from '../Assets/event2.webp'
function Home() {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
  return (
    <>
        <Navbar/>
        <div className='banner'>
        <div className='overlay'>

<h1>Technological Risk Research &<br/> Analysis Group <span>(TRAG)</span></h1>
<p><i>~Striving for a safer tomorrow~</i></p>
        </div>
        </div>
        <div className='banner-p'>
            <p>TRAG is a multidisciplinary research group aiming to prevent losses of all kinds which occur due to technology revolution. We work to make technology safer for society.

We use latest technology available to ensure that all possible risks of fire, explosion and dispersion are minimized and even if they occur then effective mitigation strategies are adopted. We have the expertise and resources to answer many of your questions on risks with process, plant and infrastructure.

Please share your thoughts with us and give us a chance to serve you</p>
        </div>
        {/* <div className='ourecore'>
        <div>
            <h3>Our Core Competencies </h3>
            <br/>
            <br/>
            <p>Customized computational multiphysics solutions</p>

            <br/>
            <p>Customized computational multiphysics solutions</p>
            <br/>
            <p>Customized computational multiphysics solutions</p>
            <br/>
            <p>Customized computational multiphysics solutions</p>
        </div>
        <div>
            <h3>Our Core Competencies </h3>
            <br/>
            <br/>
            <p>Customized computational multiphysics solutions</p>

            <br/>
            <p>Customized computational multiphysics solutions</p>
            <br/>
            <p>Customized computational multiphysics solutions</p>
            <br/>
            <p>Customized computational multiphysics solutions</p>
        </div>

        </div> */}
        <div className='heading'>
<h1>Research, Development, and
Consultancy</h1>
        </div>
        <div className='newactivity'>

        <div className='activity'>
      
        <ul>
            <li><p>

             Risk Assessment
            </p>
             </li>
            <li>TRAG performs in-depth assessments to identify and analyze risks related to fire, explosion, and hazardous material dispersion in industrial and infrastructural contexts.</li>
         

        </ul>
        <ul>
            <li><p>Computational Modelling and Simulation</p></li>
            <li>Utilizes state-of-the-art computational models to simulate scenarios involving fire, explosion, and dispersion, aiding in predicting and managing potential risks effectively.</li>

        </ul>
        <ul>
            <li><p>Advanced Measurement and Data Collection</p> </li>
            <li>Develops and implements robust safety strategies, including risk mitigation plans, emergency response protocols, and safety system designs to manage technological hazards.</li>

        </ul>
        <ul>
            <li><p>Third-Party Review and Training Program</p></li>
            <li>Conducts ongoing research to develop new risk management tools, while offering training programs and workshops to educate stakeholders on the latest safety practices and technological advancements.</li>

        </ul>
        </div>
        </div>



        
        <div className='SSD'>
    <h1>Our
    Esteemed Stakeholders</h1>
    <br/>
  
    
    <div className='partners'>
    <img src={p1}/>
    <img src={p2}/>
   

    </div>

        </div>
        <br/>

        {/* <div className='Journey'>
            <h1>Our Impactful Journey with Leading Organizations</h1>
            <br/>
            <br/>
            <img src={map}/>
        </div>
        <br/>
        <br/>
        <br/> */}

    <div className='founderdiv'>
    <div className='founder'>
        <div className='founder-left'>
        <h1>TRAG's vision</h1>
        <br/>
        <p>"TRAG’s aim is to upgrade every company's technology to it's cutting-edge by making computational engineering an integral part of their workflow"</p>
        <br/>
        <br/>
        <br/>

        <h1>TRAG's mission</h1>
        <br/>

        <p>Every 1% of improvement to your existing engineering and manufacturing pipelines can give you a "sustainable competitive advantage" on a global stage. Our scientists and engineers bring that advantage to your company by providing advanced end-to-end computational engineering solutions which are accurate, low-cost, and sustainable. And at the core of this vision is the pride in our honest scientific rigor, proven track record, and fearless working culture. </p>

</div>
 <div className='founder-right'>
 <br/>
 <img src={dir}/>
 <br/>
 <br/>
 <h3>Dr. Kirti Bhushan Mishra</h3>

<p>Assistant Professor</p>

<p>IIT Roorkee</p>
</div>

        </div>
    </div>

        <br/>
        <div className='Events'>
        <h1>Events </h1>
<br/>
<br/>
        <div className='partners'>
    <img src={e1}/>
    <img src={e2}/>
    <img src={e3}/>

   

    </div>

        </div>
        
        <Footer/>
    </>
  )
}

export default Home